import React from 'react'

const WidgetRecolectores = (props) => {
  let identificador = props.data.identreco;
  let nombre = props.data.name;
  var frg = nombre.split(" ");
  var result = frg[0];

  console.log( identificador, "props rec")
  return (
    <div className={props.data.id == props.RecolectorSelecionado?.id ? 'ContainerRecoMonitoreoCiudadActive' : ''} style={props.data.id == props.RecolectorSelecionado?.id ? { marginTop: 0 } : { marginBottom: 30 }}>
     <div onClick={() => props.AccionCargarReco(props.data)} style={{display: props.data.id == props.RecolectorSelecionado?.id || props.RecolectorSelecionado === undefined ? 'block' : 'none'}} >
      <div className='BurbujaRecoNvo' style={
        {
          background: `
                        ${props.data.status === "I" ? 'rgba(180, 0, 0, 0.46)' :
              props.data.status === "R" ? 'rgba(255, 181, 49, 0.47)' :
                props.data.status === "D" ? 'rgba(53, 193, 237, 0.48)' :
                  props.data.status === "L " ? '#rgba(59, 175, 41, 0.45)' : 'rgba(59, 175, 41, 0.45)'}`,

          animation: props.data.status === "I" ? `pulse 2s infinite` : '',

          border: ` 2px solid
           ${props.data.status === "I" ? '#B40000' :
              props.data.status === "R" ? '#FFB531' :
                props.data.status === "D" ? 'rgb(53, 193, 237)' :
                  props.data.status === "L " ? '#3BAF29' : '#3BAF29'}
          `
        }
      }>
         <p>{props.data.identreco === null ? "NC" : identificador}</p>
      </div>
      <p className='recoName'>{result}</p>
      </div>
    </div>
  )
}

export default WidgetRecolectores
